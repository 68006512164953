import {
   webview,
   country,
   community,
   masterUrl
} from "../../js/path";

export default {
   data() {
      return {
         pageNo: null,
         getCountry: [],
         getMembers: [],
         getCommunities: [],
         table_header: [],
         webview_id: null,
         fields: [{
               key: "id",
               label: "ID",
               sortable: true,
            },
            {
               key: 'webview_key',
               label: 'Webview'
            },
            {
               key: 'which_page',
               label: 'Page'
            },
            {
               key: 'is_active',
               label: 'Status'
            },
            {
               key: "edit"
            },
            {
               key: "delete"
            }
         ],
         params: "",
         filter: null,
         filterOn: [],
         tableData: [],
         sortBy: "id",
         sortDesc: true,
         key: 0,
         activeTab: 'all',
         currentPage: 1,
         whichPage: [{
               text: 'Home',
               value: 'home'
            },
            {
               text: 'Cases',
               value: 'cases'
            }
         ],
         form: {
            webview_key: '',
            webview_url: '',
            which_page: '',
            show_in_slider: 0,
            replace_page: 0,
            is_active: 0,
            valid_country: [],
            valid_member_types: [],
            valid_communities: [],
         },
         image: "",
         image_url: null,
         edit: {
            image_url: null
         },
      };
   },
   methods: {
      async fetchCountry(query) {
         const url = country.countryUrl;
         const data = await this.getRequest(url + '?search=' + query);
         if (data.status) {
            this.getCountry = data.response.data;
         }
      },
      async fetchMembers() {
         const url = masterUrl.getUserType;
         const data = await this.getRequest(url);
         if (data.status) {
            this.getMembers = data.response;
         }
      },
      async fetchCommunity(query) {
         const url = community.fetchAllActiveCommunity;
         const data = await this.getRequest(url + '&search=' + query);
         if (data.status) {
            this.getCommunities = data.response.data;
         }
      },
      async fetchSingleCommunity(id) {
         const url = community.fetchCommunity;
         const data = await this.getRequest(url + '/' + id);
         if (data.status) {
            console.log(data);
            this.form.valid_communities.push(data.response);
         }
      },
      search(event) {
         if (this.filter.length > 1) {
            if (event.keyCode == 13) {
               this.fetchData(this.activeTab);
            }
         } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
         if (this.filter.length > 0) {
            this.fetchData(this.activeTab);
         } else {
            this.fetchData(this.activeTab);
         }
      },
      filterPage() {
         if (this.pageNo.length > 0) {
            this.params = `&page=${this.pageNo}`;
            this.fetchData(this.activeTab);
            this.currentPage = this.pageNo;
         }
      },
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      readFile(e, txt) {
         if (txt == "image") {
            this.image = e.target.files[0];
            if (this.image.size > 2 * 1000 * 1000) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "error",
                  title: "Uploaded File is More than 2MB"
               });
               this.$refs.image.reset();
               this.image_url = '';
               this.edit.image_url = '';
               this.image = '';
               return false;
            } else {
               this.image_url = URL.createObjectURL(this.image);
               this.edit.image_url = '';
            }
            return true;
         }
      },
      async fetchData(txt) {
         let url = null;
         if (txt == "active") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url = webview.webviewUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = webview.webviewUrl + "?filter=" + txt;
            }
         } else if (txt == "trash") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url = webview.webviewUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = webview.webviewUrl + "?filter=" + txt;
            }
         } else if (txt == "all") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url = webview.webviewUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = webview.webviewUrl + "?filter=" + txt;
            }
         }
         url += this.params;
         this.getUrl();
         this.$store.commit("loader/updateStatus", true);
         try {
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               this.tableData = responseData;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
         this.key += 1;
      },
      getUrl() {
         var url = new URL(window.location);
         if (this.activeTab == null || this.activeTab == "") {
            this.activeTab = "all";
         }
         (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
         if (this.filter != null && this.filter != "") {
            (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
         } else {
            url.searchParams.delete('search');
         }
         if (this.currentPage != null && this.currentPage != "") {
            (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
         } else {
            url.searchParams.set('page', 1);
         }
         url.search = url.searchParams;
         url = url.toString();
         history.pushState({}, null, url);
      },
      async updateStatus(id) {
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = webview.updateStatus + "/" + id;
            const data = await this.postRequest(url, {
               is_active: !this.tableData.data[index].is_active,
            });
            if (data.status) {
               const responseData = data.response;
               this.tableData.data[index] = responseData;
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
            this.key += 1;
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
      },
      async fetchWebview(id) {
         this.$store.commit('toast/updateStatus', true);
         try {
            let url = webview.webviewUrl + '/' + id;
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               if (responseData.webview_key != "" && responseData.webview_key != null) {
                  this.form.webview_key = responseData.webview_key;
               }
               if (responseData.webview_url != "" && responseData.webview_url != null) {
                  this.form.webview_url = responseData.webview_url;
               }
               if (responseData.which_page != "" && responseData.which_page != null) {
                  this.form.which_page = responseData.which_page;
               }
               this.edit.image_url = responseData.image;
               if (responseData.show_in_slider) {
                  this.form.show_in_slider = true;
               }
               if (responseData.replace_page) {
                  this.form.replace_page = true;
               }
               if (responseData.is_active) {
                  this.form.is_active = true;
               }
               this.webview_id = responseData.id;
               if (responseData.valid_communities != "" && responseData.valid_communities != null) {
                  responseData.valid_communities.map((item) => {
                     this.fetchSingleCommunity(item);
                  })
               }
               if (responseData.valid_member_types != null && responseData.valid_member_types != "") {
                  const memberTypes = responseData.valid_member_types;
                  this.form.valid_member_types = this.getMembers.filter((item) => {
                     if (memberTypes.includes(item.value)) {
                        return item;
                     }
                  });
               }
               if (responseData.valid_country != "" && responseData.valid_country != null) {
                  responseData.valid_country.map((item) => {
                     this.form.valid_country.push({
                        name: item
                     })
                  })
               }
            }
         } catch (err) {
            console.log(err);
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!",
            });
         }
      },
      async deleteWebview(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = webview.webviewUrl + "/" + id;
            const data = await this.postRequest(url, {
               _method: "DELETE",
            });
            if (data.status) {
               this.tableData.data.splice(index, 1);
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async restoreWebview(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = webview.restoreWebview + "/" + id;
            const data = await this.postRequest(url, {});
            if (data.status) {
               this.tableData.data.splice(index, 1);
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
         try {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "error",
                  title: "Please Fill The Required Fields"
               });
               return false;
            }
            if (this.$route.name == 'add-webview') {
               if (this.form.show_in_slider == 1 && this.image != null && this.image_url != "" && this.edit.image_url == null) {
                  this.$store.commit("toast/updateStatus", {
                     status: true,
                     icon: "error",
                     title: "Image is required when Show in Slider is checked"
                  });
                  return false;
               }
            }
            if (this.$route.name == 'edit-webview') {
               if (this.form.show_in_slider == 1 && this.image == null && this.image_url == "" && this.edit.image_url == null) {
                  this.$store.commit("toast/updateStatus", {
                     status: true,
                     icon: "error",
                     title: "Image is required when Show in Slider is checked"
                  });
                  return false;
               }
               if (this.form.show_in_slider == 1 && this.image != null && this.image_url != "" && this.edit.image_url == null) {
                  this.$store.commit("toast/updateStatus", {
                     status: true,
                     icon: "error",
                     title: "Image is required when Show in Slider is checked"
                  });
                  return false;
               }
            }
            this.$store.commit("loader/updateStatus", true);
            let url = webview.webviewUrl;
            if (this.$route.name == "edit-webview") {
               url = webview.webviewUrl + "/" + this.webview_id;
            }
            let dataAppend = new FormData();

            if (this.form.show_in_slider) {
               dataAppend.append('show_in_slider', 1);
            } else {
               dataAppend.append('show_in_slider', 0);
            }

            if (this.form.replace_page) {
               dataAppend.append('replace_page', 1);
            } else {
               dataAppend.append('replace_page', 0);
            }

            if (this.form.is_active) {
               dataAppend.append('is_active', 1);
            } else {
               dataAppend.append('is_active', 0);
            }

            if (this.image) {
               dataAppend.append("image", this.image);
            }

            for (var key in this.form) {
               if (key != 'is_active' && key != 'show_in_slider' && key != 'replace_page' && key != 'valid_country' && key != 'valid_communities' && key != 'valid_member_types') {
                  dataAppend.append(key, this.form[key]);
               }
            }

            const country = [];
            this.form.valid_country.map((key) => {
               country.push(key.name);
            });
            dataAppend.append("valid_country", JSON.stringify(country));

            const community = [];
            this.form.valid_communities.map((key) => {
               community.push(key.id);
            });
            dataAppend.append("valid_communities", JSON.stringify(community));

            const member_types = [];
            this.form.valid_member_types.map((key) => {
               member_types.push(key.value);
            });
            dataAppend.append("valid_member_types", JSON.stringify(member_types));

            if (this.$route.name == "edit-webview") {
               dataAppend.append("_method", "put");
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
               this.$router.push("/webview");
            }
         } catch (err) {
            console.log(err);
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      tabActive() {
         if (this.activeTab == "trash") {
            if (this.table_header.includes("delete")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               this.fields[index] = {
                  key: "restore",
               };
               let table_index = this.table_header.findIndex(
                  (item) => item == "delete"
               );
               this.table_header[table_index] = "restore";
            } else {
               this.table_header.push("restore");
               this.fields.push({
                  key: "restore",
               });
            }

            if (!this.can("restore-webview")) {
               let index = this.fields.findIndex((item) => item.key == "restore");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "restore"
                  );
                  this.table_header.splice(table_index, 1);
               }
            }

            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                  (item) => item == "edit"
               );
               this.table_header.splice(table_index, 1);
            }
         } else {
            if (this.table_header.includes("restore")) {
               if (!this.can("edit-webview")) {
                  this.fields[4] = {
                     key: "delete",
                  };
               } else {
                  this.fields[5] = {
                     key: "delete",
                  };
               }

               let table_index = this.table_header.findIndex(
                  (item) => item == "restore"
               );
               this.table_header[table_index] = "delete";
            }

            if (!this.can("edit-webview")) {
               let index = this.fields.findIndex((item) => item.key == "edit");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "edit"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("edit")) {
                  this.table_header.push("edit");
                  this.fields[4] = {
                     key: "edit",
                  };
               }
            }
            if (!this.can("delete-webview")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "delete"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("delete")) {
                  this.table_header.push("delete");
                  this.fields[5] = {
                     key: "delete",
                  };
               }
            }
         }
      },
   },
   watch: {
      currentPage: {
         handler: function (value) {
            if (typeof value != undefined) {
               this.params = `&page=${value}`;
               this.fetchData(this.activeTab);
               this.pageNo = this.currentPage;
            }
         },
      },
      "form.is_active"(v) {
         if (v == 1) {
            this.form.is_active = true;
         } else {
            this.form.is_active = false;
         }
      },
      "form.replace_page"(v) {
         if (v == 1) {
            this.form.replace_page = true;
         } else {
            this.form.replace_page = false;
         }
      },
      "form.show_in_slider"(v) {
         if (v == 1) {
            this.form.show_in_slider = true;
         } else {
            this.form.show_in_slider = false;
         }
      },
      activeTab(v) {
         if (v) this.tabActive();
      },
   },
   created() {
      if (this.$route.name == 'add-webview' || this.$route.name == 'edit-webview') {
         this.fetchMembers();
         if (this.$route.name == "edit-webview") {
            this.fetchWebview(this.$route.params.id);
         }
      } else {
         this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
         this.table_header.push(item.key);
      });
      this.tabActive();
   }
};