<template>
   <Layout>
      <template v-if="this.$route.name == 'add-webview'">
         <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
         <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <b-form enctype="multipart/form-data" ref="webview">
                     <div class="row">
                        <b-form-group class="col-4">
                           <label for="page">Webview Key <span style="color: red">*</span></label>
                           <b-form-input id="page" v-model="form.webview_key" placeholder="Enter Webview Key"
                              :class="{ 'is-invalid': submitted && $v.form.webview_key.$error }">
                           </b-form-input>
                           <div v-if="submitted && !$v.form.webview_key.required" class="invalid-feedback">Webview Key
                              is
                              required.</div>
                        </b-form-group>
                        <b-form-group class="col-4">
                           <label for="wh">Webview URL <span style="color: red">*</span></label>
                           <b-form-input id="wh" placeholder="Enter Webview URL" v-model="form.webview_url"
                              :class="{ 'is-invalid': submitted && $v.form.webview_url.$error }">
                           </b-form-input>
                           <div v-if="submitted && !$v.form.webview_url.required" class="invalid-feedback">Webview URL
                              is
                              required.</div>
                        </b-form-group>
                        <b-form-group label="Which Page" label-for="ad" class="col-4">
                           <b-form-select id="ad" :options="whichPage" v-model="form.which_page" value-field="value"
                              text-field="text">
                           </b-form-select>
                        </b-form-group>
                        <b-form-group class="col-4">
                           <label for="country">Show to Country</label>
                           <multiselect @search-change="fetchCountry" v-model="form.valid_country" id="country"
                              :options="getCountry" :multiple="true" track-by="id" label="name"
                              placeholder="Type here to search">
                              <span slot="noOptions">
                                 Type here to search
                              </span>
                           </multiselect>
                        </b-form-group>
                        <b-form-group class="col-4">
                           <label for="mem">Show to Member Type</label>
                           <multiselect id="mem" :options="getMembers" v-model="form.valid_member_types"
                              :multiple="true" track-by="value" label="name">
                           </multiselect>
                        </b-form-group>
                        <b-form-group class="col-4">
                           <label for="comm">Community</label>
                           <multiselect id="comm" @search-change="fetchCommunity" :options="getCommunities"
                              v-model="form.valid_communities" :multiple="true" track-by="id" label="title">
                           </multiselect>
                        </b-form-group>
                        <b-form-group label="Image [Upload Max File Size : 2MB]" class="col-12" label-for="img">
                           <b-form-file accept="image/*" placeholder="Choose a file or drop it here..."
                              @change="readFile($event, 'image')" ref="image" id="img">
                           </b-form-file>
                           <template v-if="$route.name == 'edit-webview' && edit.image_url">
                              <img :src="edit.image_url" width="128px" height="128px"
                                 style="object-fit: contain; margin-top: 5px" />
                           </template>
                           <template v-if="image_url">
                              <img :src="image_url" width="128px" height="128px" ref="image_url"
                                 style="object-fit: contain; margin-top: 5px" />
                           </template>
                        </b-form-group>
                        <b-form-group class="col-12">
                           <div style="display: flex; gap: 10px">
                              <b-form-checkbox v-model="form.show_in_slider">Show in Slider</b-form-checkbox>
                              <b-form-checkbox v-model="form.replace_page">Replace Page</b-form-checkbox>
                              <b-form-checkbox v-model="form.is_active">Is Active</b-form-checkbox>
                           </div>
                        </b-form-group>
                     </div>
                     <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData">
                        <span v-if="$route.name == 'add-webview'">Save Data</span>
                        <span v-else-if="$route.name == 'edit-webview'">Update Data</span>
                     </b-button>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import MixinRequest from "../../../mixins/request";
import webviewMixin from "../../../mixins/ModuleJs/webview";
import { required } from "vuelidate/lib/validators";

export default {
   data() {
      return {
         submitted: false,
         title1: "Add Webview",
         title2: "Edit Webview",
         items: [
            {
               text: "List",
               href: "/webview",
            },
            {
               text: "Data",
            },
         ],
      };
   },
   mixins: [MixinRequest, webviewMixin],
   components: {
      Layout,
      Multiselect,
      PageHeader,
   },
   validations: {
      form: {
         webview_key: { required },
         webview_url: { required },
      },
   }
};
</script>
 